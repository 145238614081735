<template>
    <content-wrapper>
        <w-data-table
            :headers="headers"
            :filter-by="[ 'title', 'description' ]"
            searchable
            itemKey="uuid"
            :items="items"
        >
            <template #actions>
                <w-btn-action
                    :text="$trans('Add location')"
                    icon="ADD"
                    @click="onShowForm"
                />
            </template>
            <template #item.actions="{ item }">
                <div class="d-flex justify-end">
                    <v-btn
                        icon
                        color="success"
                        @click="onShowForm(item)"
                    >
                        <w-icon size="20" value="mdi-pencil"/>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        @click="onDelete({}, item)"
                    >
                        <w-icon size="20" value="mdi-delete"/>
                    </v-btn>
                </div>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isDrawer"
            :title="$trans('Location')"
            :loading="loading"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="'groupForm' + formKey"
                    :fields="formFields"
                    :item="item"
                    :errors="errors"
                    deletable
                    :disabled="loading"
                    @save="onStore"
                    @update="onUpdate"
                    @delete="onDelete"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { required } from '@/utils/validation'
import {
    deleteLessonLocation,
    getLessonLocationsCollection,
    storeLessonLocation,
    updateLessonLocation
} from '@apps/school/api/lessonLocations'

export default {
    name: 'LessonLocationsManage',
    components: { ContentWrapper },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Title'),
                    value: 'title'
                },
                {
                    text: this.$trans('Description'),
                    value: 'description'
                },
                {
                    align: 'right',
                    value: 'actions'
                }
            ]
        },
        formFields() {
            return [
                {
                    name: 'title',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Title'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'description',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Description'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            isDrawer: false,
            formKey: 0,
            item: {},
            items: [],
            filterByLevel: null,
            draggedData: null
        }
    },
    created() {
        this.fetchLessonLocations()
    },
    methods: {
        onShowForm(item) {
            this.item = item || {}
            this.formKey++
            this.isDrawer = true
        },
        async fetchLessonLocations() {
            this.$lprogress.begin()

            try {
                const response = await getLessonLocationsCollection()
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        },
        async onStore(data) {
            this.loading = true
            try {
                await storeLessonLocation(data)
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onUpdate(data, item) {
            this.loading = true
            try {
                await updateLessonLocation(item.uuid, data)
                await this.fetchLessonLocations()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onDelete(data, item) {
            this.isDrawer = false

            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${item.title}`
            }))

            if (!isConfirm) {
                return
            }

            this.$lprogress.begin()

            try {
                await deleteLessonLocation(item.uuid)
                await this.fetchLessonLocations()
                this.$lprogress.end()
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
